<template>
  <div class="subscibe-wrap" v-if="state.list.length">
    <div class="count-wrap">共{{ state.list.length }}条</div>
    <div class="subscribe-item" v-for="item in state.list" :key="item.id">
      <div class="search-wrap" @click="subscribeHandle(item)">
        <div
          v-for="(search, index) in item.content.split('\t')"
          :key="index"
          :class="{ 'search-item': judgeClass(search), 'search-item-active': judgeActiveClass(search) }"
        >{{ search }}</div>
      </div>
      <div class="footer-wrap">
        <div class="date">{{ item.date }} {{ item.time }}</div>
        <div class="updata">7日更新 {{ item.seven_days_count }}</div>
        <div class="delete" @click="deleteHandle(item)">
          <Icon name="delete" size="18" color="#0C86FE" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="empty">
    <Empty description="暂无数据" />
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Icon, Dialog, Empty } from 'vant'
import { getSubscribe, deleteSubscribe } from '@/api/'
export default {
  components: {
    Icon,
    Empty
  },
  setup () {
    // document.title = '订阅的检索式'
    const state = reactive({
      list: []
    })
    const router = useRouter()

    const fetchSubscribe = () => {
      getSubscribe({
        is_subscribed: 1
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          state.list = res.data
        }
      })
    }

    fetchSubscribe()

    const deleteHandle = (item) => {
      Dialog.confirm({
        title: '确认删除本条检索式？',
        confirmButtonColor: '#007aff'
      })
        .then(() => {
          deleteSubscribe(item.id, {
            is_subscribed: '0'
          }).then(res => {
            if (res.code === 200) {
              fetchSubscribe()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    }

    const judgeClass = (search) => {
      if (search === 'and' || search === 'OR' || search === 'AND' || search === 'And') {
        return true
      } else {
        return false
      }
    }

    const judgeActiveClass = (search) => {
      if (search !== 'and' && search !== 'OR' && search !== 'AND' && search !== 'And') {
        return true
      } else {
        return false
      }
    }

    const subscribeHandle = (item) => {
      router.push(`/advanceList?search=${item.search_content}&content=${item.content}`)
    }

    return {
      state,
      deleteHandle,
      subscribeHandle,
      judgeActiveClass,
      judgeClass
    }
  }
}
</script>

<style lang="less" scoped>
.subscibe-wrap {
  .count-wrap {
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-size: 14px;
    color: #999999;
  }
  .subscribe-item {
    padding: 20px 16px;
    position: relative;
    background: #fff;
    &::after {
      content: '';
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0;
      bottom: 0;
      left: 16px;
      border-bottom: 1px solid #ebedf0;
      transform: scaleY(.5);
    }
    .search-wrap {
      display: flex;
      flex-wrap: wrap;
      .search-item-active {
        padding: 4px 12px;
        background: #EBF5FF;
        border-radius: 14px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .search-item {
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        padding: 4px 0;
        margin: 0 8px;
      }
    }
    .footer-wrap {
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
    }
  }
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
